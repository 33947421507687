import { SinglePermit } from "@/model/api/Permit";

interface PermitItemProps {
  data: SinglePermit;
  onClick: (data: SinglePermit) => void;
  onSelect: (id: string, isSelected: boolean) => void;
}

export default function PermitItem({
  data,
  onClick,
  onSelect,
}: PermitItemProps) {
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSelect(data.SK, e.target.checked);
  };

  return (
    <div className="relative p-4 rounded-container mb-2 cursor-pointer">
      <div className="flex-col" key={data.SK} onClick={() => onClick(data)}>
        <h5 className="font-medium">{data.fileName}</h5>
      </div>
      <input
        type="checkbox"
        className="input-checkbox absolute top-2 right-2"
        onChange={handleCheckboxChange}
      />
    </div>
  );
}
