import axios from "axios";
import { sharedEndpoints } from "./shared";
import { weatherApi } from "./weather";
import { setAuth } from "../store/actions";
import { store } from "../store";
import { tokenHandler } from "./tokenHandler";

// https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript-without-using-a-library
export function parseJwt(token) {
  if (!token) return null;
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join("")
  );

  return JSON.parse(jsonPayload);
}
let currentAccessTokenUsed;
export const createOperatorRequestInstance = (jwtToken) => {
  /**
   * Mars API
   */
  const mars = axios.create({
    baseURL: store.getState().envVar["base_url-mars"].Value,
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${jwtToken}`,
    },
  });

  const mavlinkurl = store.getState().envVar["base_url-mavlink"].Value;

  const gnssUrl = store.getState().envVar.gnss_api_url.Value;
  /**
   * Mars Error Handling
   * Workflow
   * 1. Interceptor Request
   *  -if token expiring, refresh token before submit
   *  -if refresh token expired, attemptrefresh() will trigger logout
   *  -leave other error handling to response
   *
   * 2. Interceptor Response
   *  -handle response errors
   *  -check token expired in case expired right after request sent
   */

  const handleInterceptorResponseError = async (error) => {
    if (currentAccessTokenUsed) {
      try {
        const newToken = await attemptRefresh();
        const { config } = error;
        config.headers.Authorization = `Bearer ${newToken}`;
        return axios.request(config);
      } catch {
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  };

  const refreshAuthInfo = (idToken, accessToken) => {
    const userInfo = parseJwt(idToken);
    store.dispatch(
      setAuth({
        accessToken,
        userInfo,
        isPilot: true,
      })
    );
  };

  const attemptRefresh = async () => {
    try {
      // handle refresh token here
      const access_token = await tokenHandler().getUserAccessToken();
      const idToken = await tokenHandler().getUserIdToken();
      currentAccessTokenUsed = access_token;
      refreshAuthInfo(idToken, access_token);
      return access_token;
    } catch (err) {
      console.log("Mars Error : ", err);
      handleSessionExpired(err);
    }
    return null;
  };

  let sessionExpiredTriggered = false;
  const handleSessionExpired = (e) => {
    if (!sessionExpiredTriggered) {
      alert("Your session has expired. Please login again.");
      localStorage.clear();
      window.location.href = "/login";
      sessionExpiredTriggered = true;
    }
  };

  mars.interceptors.request.use(async (config) => {
    if (!jwtToken) return new axios.Cancel("No token no request");
    const accessToken = currentAccessTokenUsed || jwtToken;
    if (accessToken) {
      // decode accessToken
      const jwtPayload = JSON.parse(window.atob(accessToken.split(".")[1]));
      // refresh token when expired
      if (jwtPayload.exp <= Date.now() / 1000) {
        try {
          const newToken = await attemptRefresh();
          return {
            ...config,
            headers: {
              ...config.headers,
              "content-type": "application/json",
              Authorization: `Bearer ${newToken}`,
            },
          };
        } catch (err) {
          // should not trigger, attemptRefresh should trigger logout on error
          console.log("Mars Error : ", err);
        }
      } else {
        return {
          ...config,
          headers: {
            ...config.headers,
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        };
      }
    }
  });

  mars.interceptors.response.use(
    (config) => config,
    (error) => {
      return handleInterceptorResponseError(error);
    }
  );

  /**
   * Functions
   */

  const getPilotOperations = (pilot_uuid, offset) => {
    return mars.get(
      `/operations/list/?isPast=true&assetType=pilot&assetId=${pilot_uuid}&tags=operation${
        offset ? `&offset=${offset}` : "&offset=0"
      }`
    );
  };

  const getPlatformOperations = (platform_uuid, offset) => {
    return mars.get(
      `/operations/list/?isPast=true&assetType=platform&assetId=${platform_uuid}&tags=operation${
        offset ? `&offset=${offset}` : "&offset=0"
      }`
    );
  };

  const getPilots = () => mars.get("/pilot/list");
  const getPilot = (pilot_uuid) => mars.get(`/pilot/${pilot_uuid}`);

  const getLogState = (offset) => {
    let queryString = `/operations/state/logs`;
    if (offset) queryString += `?offset=${offset}`;
    return mars.get(queryString);
  };
  const getOperationLogState = (operationId) => {
    let queryString = `/operations/state/logs`;
    if (operationId) queryString += `?opId=${operationId}`;
    return mars.get(queryString);
  };

  const createPilot = ({ name, username, email, phone, license, expiry }) =>
    mars.request({
      method: "post",
      url: `/pilot`,
      data: {
        pilot_name: name,
        pilot_username: username,
        pilot_email: email,
        pilot_contact: phone,
        pilot_registration: license,
        registration_expiry: expiry,
      },
    });

  const deletePilot = (pilot_uuid, pilot_username) =>
    mars.request({
      method: "delete",
      url: `/pilot/${pilot_uuid}`,
      headers: {
        "content-type": "application/json",
      },
      data: {
        pilot_username,
      },
    });

  const updatePilot = (pilot_uuid, { license, expiry }) =>
    mars.request({
      method: "put",
      url: `/pilot/${pilot_uuid}`,
      data: {
        pilot_registration: license,
        registration_expiry: expiry,
      },
    });

  const getRemoteId = () => mars.get("/remote-id/list");

  const createRemoteId = ({ username, email }) =>
    mars.request({
      method: "post",
      url: `/remote-id`,
      data: {
        remote_id_username: username,
        remote_id_email: email,
      },
    });

  const updateRemoteId = (username, { email }) =>
    mars.request({
      method: "put",
      url: `/remote-id/${username}`,
      data: {
        remote_id_email: email,
      },
    });

  const deleteRemoteId = (username) =>
    mars.request({
      method: "delete",
      url: `/remote-id/${username}`,
      headers: {
        "content-type": "application/json",
      },
    });

  const submitNewPlatform = ({
    callsign,
    registration,
    expiry,
    // puckUUID,
    platformType,
    remotecontroller,
    flightcontroller,
  }) =>
    mars.request({
      method: "post",
      url: `/platform`,
      data: {
        platform_callsign: callsign,
        platform_registration: registration,
        registration_expiry: expiry,
        // puck_uuid: puckUUID,
        platform_type_uuid: platformType,
        remote_controller_sn: remotecontroller,
        flight_controller_sn: flightcontroller,
      },
    });

  const updatePlatformInfo = ({
    platform_uuid,
    callsign,
    registration,
    expiry,
    // puckID,
    platformType,
    remotecontroller,
    flightcontroller,
  }) =>
    mars.request({
      method: "put",
      url: `/platform/${platform_uuid}`,
      data: {
        platform_callsign: callsign,
        platform_registration: registration,
        registration_expiry: expiry,
        // puck_uuid: puckID,
        platform_type_uuid: platformType,
        remote_controller_sn: remotecontroller,
        flight_controller_sn: flightcontroller,
      },
    });

  const getPlatform = (platform_uuid) =>
    mars.request({
      method: "get",
      url: `/platform/${platform_uuid}`,
    });
  const submitNewTracker = ({
    trackername,
    trackerSn,
    simexpiry,
    trackerimei,
  }) =>
    mars.request({
      method: "post",
      url: `/tracker`,
      data: {
        tracker_sn: trackerSn,
        tracker_imei: trackerimei,
        tracker_name: trackername,
        sim_expiry: simexpiry,
      },
    });
  const getTracker = () =>
    mars.request({
      method: "get",
      url: `/tracker/list`,
    });
  const getTrackerScan = (tracker_uuid) =>
    mars.request({
      method: "get",
      url: `/tracker/${tracker_uuid}`,
    });
  const updateTracker = ({
    selectedtrackeruuid,
    trackername,
    trackerSn,
    simexpiry,
    trackerimei,
  }) =>
    mars.request({
      method: "put",
      url: `/tracker/${selectedtrackeruuid}`,
      data: {
        tracker_imei: trackerimei,
        tracker_name: trackername,
        tracker_sn: trackerSn,
        sim_expiry: simexpiry,
      },
    });
  const deleteTracker = (tracker_uuid) =>
    mars.delete(`/tracker/${tracker_uuid}`);

  const getOperationData = ({ operationID }) =>
    mars.request({
      method: "get",
      url: `/operations/${operationID}`,
    });

  const getMaintenance = () => mars.get("/platform/maintenance/list");

  const getMaintenanceData = ({ platform_uuid }) =>
    mars.request({
      method: "get",
      url: `/platform/maintenance/${platform_uuid}`,
    });

  const deleteMaintenance = (record_uuid) =>
    mars.request({
      method: "delete",
      url: `/platform/maintenance/${record_uuid}`,
      headers: {
        "content-type": "application/json",
      },
    });

  const submitNewMaintenance = (data) =>
    mars.request({
      method: "post",
      url: `/platform/maintenance`,
      data,
    });

  const updateMaintenance = (record_uuid, data) =>
    mars.request({
      method: "put",
      url: `/platform/maintenance/${record_uuid}`,
      data,
    });

  const submitNewPlatformType = (data) =>
    mars.request({
      method: "post",
      url: `/platform/type`,
      data,
    });

  const updatePlatformTypeInfo = (platform_type_uuid, data) =>
    mars.request({
      method: "put",
      url: `/platform/type/${platform_type_uuid}`,
      data,
    });

  const getPlatformTypes = () =>
    mars.request({
      method: "get",
      url: `/platform/type/list`,
    });

  const getPlatformType = (platform_type_uuid) =>
    mars.request({
      method: "get",
      url: `/platform/type/${platform_type_uuid}`,
    });

  const submitOperation = (data) =>
    // set way points including p2p and intermediate
    mars.request({
      method: "post",
      url: `/operations/wps`,
      data,
    });
  const submitAreaOperation = (data) =>
    // set area operations
    mars.request({
      method: "post",
      url: `/operations`,
      data,
    });
  const getPlatforms = () => mars.get(`/platform/list`);

  const getOperations = ({
    isPast,
    offset,
    isOperations,
    isRidOperations,
    operationStates,
  }) => {
    let url = "/operations/list";
    url += isPast ? "?isPast=true" : "?isPast=false";
    url += offset ? `&offset=${offset}` : "";
    url += isOperations ? "&tags=operation" : "";
    url += isOperations && isRidOperations ? ",rid" : "";
    url += !isOperations && isRidOperations ? "&tags=rid" : "";
    url += operationStates ? `&operationStates=${operationStates}` : "";
    return mars.get(url);
  };

  const getActiveOperations = (timeStart, timeEnd, ignoreLanded) => {
    return mars.get(
      `/operations/list/timeframe?timeStart=${timeStart}&timeEnd=${timeEnd}&ignoreLanded=${ignoreLanded}&tags=operation`
    );
  };

  const deleteOperation = (operationID) =>
    mars.request({
      method: "delete",
      url: `/operations/${operationID}`,
      headers: {
        "content-type": "application/json",
      },
    });

  const updateOperation = (operationID, operationState) =>
    mars.request({
      method: "post",
      url: "/operations/state",
      headers: {
        "content-type": "application/json",
      },
      data: {
        operation_uuid: operationID,
        new_state: operationState,
      },
    });

  const editOperation = (operationID, operation, force = "false") =>
    mars.request({
      method: "put",
      url: `/operations/${operationID}?force=${force}`,
      headers: {
        "content-type": "application/json",
      },
      data: operation,
    });

  const postMissionDetails = (OperationDetails, broadcast, env, uasID) =>
    mars.request({
      method: "post",
      url: `/operations/takeoff`,
      headers: {
        "content-type": "application/json",
      },
      data: {
        operation_uuid: OperationDetails.reference.id,
        env,
        broadcast,
        uas_id: uasID,
      },
    });

  const postMavlinkConnection = (
    trackerID,
    FlightControllerSN,
    platformName,
    udpPort,
    altitude,
    groundSpeed,
    waypoints
  ) =>
    mars.request({
      method: "post",
      url: mavlinkurl,
      headers: {
        "content-type": "application/json",
      },
      data: {
        serial_number: FlightControllerSN,
        udpPort,
        platformName,
        trackerID,
        altitude,
        groundSpeed,
        waypoints,
      },
    });

  // /**
  //  * MarineTraffic API
  //  */
  //  const marineTrafficBaseURL = "https://services.marinetraffic.com/api/exportvessels/fc14e1966a9e9e9a9d445ba0e794c520539bfe82?v=8&timespan=5&msgtype=extended&protocol=json";

  // const getThaiShips = () => {
  //   return marineTraffic.get(marineTrafficBaseURL);
  //     // headers: {
  //     //   Authorization: null,
  //     // },
  //     // data: {
  //     //   v: 8,
  //     //   MAXLAT: 13.19086,
  //     //   MINLON: 100.77824,
  //     //   MAXLON: 100.84073,
  //     //   MINLAT: 13.10761,
  //     //   protocol: "json",
  //     //   msgtype: "simple",
  //     // },
  //   });
  // };

  const getLogMessages = (data) =>
    mars.request({
      method: "POST",
      url: `/log-messages/get`,
      data,
    });

  const createLogMessage = (data) =>
    mars.request({
      method: "POST",
      url: `/log-messages`,
      data,
    });

  const deletePlatformType = (platform_type_uuid) =>
    mars.delete(`/platform/type/${platform_type_uuid}`);

  const deletePlatform = (platform_uuid) =>
    mars.delete(`/platform/${platform_uuid}`);

  const addOperationSelection = (operation_uuid) =>
    mars.post(`/operations/selection`, { operation_uuid });

  const removeOperationSelection = (operation_uuid) =>
    mars.delete(`/operations/selection/${operation_uuid}`);

  const getAllOperationSelection = () => mars.get(`/operations/selection`);

  const getOperationOwner = (operation_uuids) =>
    mars.post(`/operations/owner`, operation_uuids);

  /**
   *
   * @param {<Object>} {"operation_uuids": Array of operation_uuids}
   * @returns {<Array>} Array of operations with operation_uuid, creator_id, and an Array of approved users.
   */
  const getOperationsApprovedUsers = (operation_uuids) =>
    mars.post(`/operations/approved-users`, operation_uuids);

  const getPermits = () => {
    return mars.get("/permit/list");
  };

  const getPermitUploadURL = (data) =>
    mars.request({
      method: "POST",
      url: `/permit/upload`,
      data: {
        loginUser: data.loginUser,
        fileName: data.fileName,
        wayPoints: data.wayPoints,
        activityStart: data.activityStart,
        permitExpiry: data.permitExpiry,
        altitude: data.altitude,
        location: data.location,
      },
    });

  const updatePermitDetail = (data) => {
    const encodedData = encodeURIComponent(data.sortKey);
    mars.request({
      method: "PUT",
      url: `/permit/${encodedData}`,
      data: {
        fileName: data.fileName,
        wayPoints: data.wayPoints,
        activityStart: data.activityStart,
        permitExpiry: data.permitExpiry,
        altitude: data.altitude,
        location: data.location,
      },
    });
  };

  const deletePermit = (key) => {
    const encodedData = encodeURIComponent(key);
    return mars.request({
      method: "delete",
      url: `/permit/${encodedData}`,
      headers: {
        "content-type": "application/json",
      },
      data: {
        key,
      },
    });
  };

  const getPermitDownloadURL = (file_name) => {
    const encodedData = encodeURIComponent(file_name);
    return mars.get(`/permit/download/${encodedData}`);
  };

  const getWayline = (user) => {
    return mars.get(`/wayline/${user}`);
  };

  const deleteWayline = (user, filename) => {
    return mars.delete(`/wayline/${user}?fileName=${filename}`);
  };

  const getWaylineUploadURL = (data) =>
    mars.request({
      method: "POST",
      url: `/wayline/upload`,
      data: {
        loginUser: data.loginUser,
        fileName: data.fileName,
      },
    });

  const getWaylineMetaData = (file_name) => {
    const encodedData = encodeURIComponent(file_name);
    return mars.get(`/wayline/metadata/${encodedData}`);
  };

  const getWaylineDownloadURL = (file_name) => {
    const encodedData = encodeURIComponent(file_name);
    return mars.get(`/wayline/download/${encodedData}`);
  };

  const getWaylineDeconflict = (login_user) => {
    return mars.get(`/wayline/${login_user}`);
  };

  const getWaylineUploadURLDeconflict = (data) =>
    mars.request({
      method: "POST",
      url: `/wayline/upload`,
      data: {
        loginUser: data.loginUser,
        fileName: data.fileName,
      },
    });

  const getWaylineMetaDataDeconflict = (file_name) => {
    const encodedData = encodeURIComponent(file_name);
    return mars.get(`/wayline/metadata/${encodedData}`);
  };

  const getWaylineDownloadURLDeconflict = (file_name) => {
    const encodedData = encodeURIComponent(file_name);
    return mars.get(`/wayline/download/${encodedData}`);
  };

  const getGnssData = (coordinates) => {
    return mars.get(
      `${gnssUrl}?nwData=${JSON.stringify(
        coordinates.nwData
      )}&neData=${JSON.stringify(coordinates.neData)}&swData=${JSON.stringify(
        coordinates.swData
      )}&seData=${JSON.stringify(coordinates.seData)}&altitude=${
        coordinates.altitude
      }`
    );
  };

  const sendEmail = (recipientUsername, message, subject) => {
    mars.request({
      method: "POST",
      url: `/send-email`,
      data: {
        recipientUsername,
        message,
        subject,
      },
    });
  };

  const submitPortOperation = (data) => {
    return mars.request({
      method: "POST",
      url: `/operations/corridor`,
      data,
    });
  };

  const getCorridorAvailability = (data) => {
    return mars.request({
      method: "POST",
      url: `/operations/corridor/availability`,
      data,
    });
  };

  const saveRCInformation = (data, userUUID) => {
    return mars.request({
      method: "POST",
      url: `/workspaces/${userUUID}/devices/save`,
      baseURL: `${
        store.getState().envVar["base_url-mars"].Value
      }/dji-cloud/manage/api/v1`,
      data,
    });
  };

  const updateUserRole = (data) => {
    return mars.request({
      method: "PUT",
      url: `/users/${data.username}/role`,
      data: {
        current_user_role: data.current_user_role,
        user_roles: data.user_roles,
      },
    });
  };

  const updateUserDetails = (data) => {
    return mars.request({
      method: "PUT",
      url: `/users/${data.username}`,
      data: {
        email: data.email,
        name: data.name,
        contact: data.contact,
      },
    });
  };

  const getUser = (username) => {
    return mars.get(`/users/${username}`);
  };

  const createUser = (data) => {
    return mars.request({
      method: "POST",
      url: `/users`,
      data: {
        username: data.username,
        email: data.email,
        user_roles: data.user_roles,
        privileges: data.privileges,
        default_role: data.current_user_role,
        name: data.name,
        contact: data.contact,
      },
    });
  };

  const getUserPrivilege = (username) => {
    return mars.get(`/users/${username}/privilege`);
  };

  const updateUserPrivilege = (data) => {
    return mars.request({
      method: "PUT",
      url: `/users/${data.username}/privilege`,
      data: {
        privileges: data.privileges,
      },
    });
  };

  const deleteUser = (username) => mars.delete(`/users/${username}`);

  const expelUser = (username) =>
    mars.delete(`/organisation/users/${username}`);

  const getOrganisationUsers = () => {
    return mars.get(`/organisation/users/list`);
  };

  const getOrganisationRoles = (fullList = false) => {
    return mars.get(
      `/organisation/subscription/role${fullList ? "?full_list=true" : ""}`
    );
  };

  const getOrganisationPrivilege = (fullList = false) => {
    return mars.get(
      `/organisation/subscription/privilege${fullList ? "?full_list=true" : ""}`
    );
  };

  const getOwnOrganisation = () => mars.get(`/organisation`);

  const updateOwnOrganisation = ({ organisation_name }) =>
    mars.request({
      method: "put",
      url: `/organisation`,
      data: {
        organisation_name,
      },
    });

  const inviteUserToOrganisation = (user_uuid) =>
    mars.post(`/organisation/users/${user_uuid}`);

  const getSchedules = (timeStart, offset, scheduleTags, scheduleStates) => {
    let params = "";
    params += offset ? `?offset=${offset}` : "?offset=0";
    params += timeStart ? `&timeStart=${timeStart}` : "";
    params += scheduleTags ? `&scheduleTags=${scheduleTags}` : "";
    params += scheduleStates ? `&scheduleStates=${scheduleStates}` : "";
    const url = params ? `/schedules${params}` : "/schedules";
    return mars.get(url);
  };

  const getScheduleById = (schedule_id) =>
    mars.get(`/schedules/${schedule_id}`);

  const deleteSchedule = (schedule_id) =>
    mars.delete(`/schedules/${schedule_id}`);

  const submitSchedule = (data) =>
    mars.request({
      method: "post",
      url: `/schedules`,
      data,
    });

  const editSchedule = (scheduleId, schedule) =>
    mars.request({
      method: "put",
      url: `/schedules/${scheduleId}`,
      headers: {
        "content-type": "application/json",
      },
      data: schedule,
    });

  const promoteSchedule = (schedule_id, skipVesselCheck) =>
    mars.post(
      `/schedules/${schedule_id}/confirm${
        skipVesselCheck ? "?skipVesselCheck=true" : ""
      }`
    );

  const getOperationSimulation = (operation_uuid) =>
    mars.get(`/weather-simulation/operations/${operation_uuid}`);

  const getScheduleSimulation = (schedule_id) =>
    mars.get(`/weather-simulation/schedules/${schedule_id}`);

  const getSimulationDetails = (schedule_id) =>
    mars.get(`/weather-simulation/simulations/${schedule_id}`);

  const submitOperationSimulation = (
    operation_uuid,
    flight_simulation,
    mission_simulation
  ) => {
    let params = "";
    if (flight_simulation && mission_simulation) {
      params += "?simulation_type=flight,mission";
    } else if (flight_simulation) {
      params += "?simulation_type=flight";
    } else if (mission_simulation) {
      params += "?simulation_type=mission";
    }
    return mars.post(
      `/weather-simulation/operations/${operation_uuid}${params}`
    );
  };

  const submitScheduleSimulation = (
    schedule_uuid,
    flight_simulation,
    mission_simulation
  ) => {
    let params = "";
    if (flight_simulation && mission_simulation) {
      params += "?simulation_type=flight,mission";
    } else if (flight_simulation) {
      params += "?simulation_type=flight";
    } else if (mission_simulation) {
      params += "?simulation_type=mission";
    }
    return mars.post(`/weather-simulation/schedules/${schedule_uuid}${params}`);
  };

  const deleteSimulation = (simulation_uuid) =>
    mars.delete(`/weather-simulation/simulations/${simulation_uuid}`);

  return {
    getOperationLogState,
    getCorridorAvailability,
    submitPortOperation,
    sendEmail,
    getGnssData,
    getPermits,
    getPermitUploadURL,
    getPermitDownloadURL,
    deletePermit,
    updatePermitDetail,
    getWayline,
    getWaylineUploadURL,
    getWaylineMetaData,
    getWaylineDownloadURL,
    getWaylineDeconflict,
    getWaylineUploadURLDeconflict,
    getWaylineMetaDataDeconflict,
    getWaylineDownloadURLDeconflict,
    deleteWayline,
    getRemoteId,
    createRemoteId,
    updateRemoteId,
    deleteRemoteId,
    getPilotOperations,
    getPlatformOperations,
    getPilots,
    getPilot,
    createPilot,
    updatePilot,
    deletePilot,
    submitNewPlatform,
    updatePlatformInfo,
    submitNewPlatformType,
    updatePlatformTypeInfo,
    getPlatform,
    getOperationData,
    submitOperation,
    submitAreaOperation,
    getPlatforms,
    getOperations,
    getActiveOperations,
    deleteOperation,
    editOperation,
    updateOperation,
    postMissionDetails,
    getLogMessages,
    createLogMessage,
    getPlatformType,
    getPlatformTypes,
    deletePlatformType,
    deletePlatform,
    addOperationSelection,
    getAllOperationSelection,
    removeOperationSelection,
    getLogState,
    getTracker,
    getTrackerScan,
    submitNewTracker,
    updateTracker,
    deleteTracker,
    getMaintenanceData,
    getMaintenance,
    deleteMaintenance,
    submitNewMaintenance,
    updateMaintenance,
    getOperationOwner,
    getOperationsApprovedUsers,
    saveRCInformation,
    getUser,
    createUser,
    deleteUser,
    expelUser,
    updateUserRole,
    updateUserDetails,
    getOrganisationUsers,
    getOrganisationRoles,
    getOrganisationPrivilege,
    getOwnOrganisation,
    inviteUserToOrganisation,
    getUserPrivilege,
    updateUserPrivilege,
    updateOwnOrganisation,
    getSchedules,
    getScheduleById,
    submitSchedule,
    deleteSchedule,
    editSchedule,
    promoteSchedule,
    postMavlinkConnection,
    getOperationSimulation,
    getScheduleSimulation,
    getSimulationDetails,
    submitOperationSimulation,
    submitScheduleSimulation,
    deleteSimulation,
    weather: weatherApi(),
    jwtToken,
    ...sharedEndpoints,
  };
};
