import {
  createAuthorityRequestInstance,
  createOperatorRequestInstance,
} from ".";
import useCognitoAuth from "../hooks/useCognitoAuth";

let instance = null;
export const useApi = () => {
  const { accessToken } = useCognitoAuth();
  if (instance) return instance;
  if (!accessToken) return null;
  instance = createOperatorRequestInstance(accessToken);
  instance.aas = createAuthorityRequestInstance(accessToken);
  return instance;
};
