import { React, useEffect } from "react";
import "./output.css";

import {
  BrowserRouter,
  Route,
  Routes,
  useNavigate,
  Outlet,
  useLocation,
} from "react-router-dom";
import { CssBaseline } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import SnackbarMessage from "./components/SnackbarMessage";
import { setAuth, setEnvVar } from "./store/actions";
import useWebsocket from "./hooks/useWebsocket";
import { AppColourThemeProvider } from "./contexts/AppColourTheme";
import LoginPage from "./pages/Login";
import LandingPage from "./pages/v1/Landing";
import DashboardPage from "./pages/v1/Dashboard";
import DirectAccess from "./pages/v1/DirectAccess";
import { parameterStore } from "./api/parameterStore";
import PuckServer from "./services/puckserver";
import { sharedEndpoints } from "./api/shared";
import { setAirmapspaceApiKey } from "./api/airmapspace";
import { tokenHandler } from "./api/tokenHandler";

let wsBaseUrl = "";

function Auth() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { updateWsUrl, updateWsBaseUrl } = useWebsocket({
    channel: "notification",
  });

  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  const initAuthInfo = (accessToken) => {
    dispatch(
      setAuth({
        accessToken,
        isLoggedIn: !!accessToken,
      })
    );
    initializeFOHEnvVariables();
  };

  // used to handle functions inialized before login
  const handleEarlierInitializedFunctions = (formatEnvVar) => {
    // Puckserver service/puckserver.js
    PuckServer.updatePuckserverBaseUrl(
      `https://${formatEnvVar["base_url-tracker_server"].Value}`
    );

    // Shared Api api/shared/shared.js
    const sharedEndpointsBaseUrls = {
      sdspBaseUrl: formatEnvVar["base_url-cif"].Value,
      cifBaseUrl: formatEnvVar["base_url-cif"].Value,
      cifAuthUrl: `${formatEnvVar["cognito_domain-cif"].Value}/oauth2/token`,
      fimsAtmUrl: formatEnvVar["base_url-cif_atm"].Value,
      fimsClientId: formatEnvVar["app_client_id-cif"].Value,
      fimsClientSecret: formatEnvVar["app_client_secret-cif"].Value,
    };
    sharedEndpoints.updateSharedApiBaseUrl(sharedEndpointsBaseUrls);

    // Airmapspace /api/airmapspace/airmapspace.js
    setAirmapspaceApiKey(formatEnvVar["api_key-airmap"].Value);

    // useWebsocket
    wsBaseUrl = `wss://${formatEnvVar["base_url-notifications"].Value}`;
    updateWsBaseUrl(wsBaseUrl);
  };

  const initializeFOHEnvVariables = async () => {
    const formatEnvVar = {};
    try {
      const getEnvVar = await parameterStore();
      // TODO add encrption for type SecureString
      getEnvVar.data.forEach((singleEnvVar) => {
        formatEnvVar[singleEnvVar.Name] = singleEnvVar;
      });
      dispatch(setEnvVar(formatEnvVar));
      handleEarlierInitializedFunctions(formatEnvVar);

      console.log("initialize success");

      const focusedOperationId = localStorage.getItem("focusedOperationId");
      if (focusedOperationId) {
        navigate(
          `/v1/operationfocused/${focusedOperationId.replace(/"/g, "")}`
        );
      } else {
        navigate("/v1/dashboard");
      }
    } catch (e) {
      // failed to initiate env vars
      localStorage.clear();

      navigate("/v1");
    }
  };

  const checkIfAuthenticated = async () => {
    const tokenAccess = tokenHandler();
    const accessToken = await tokenAccess.getUserAccessToken();
    const idToken = await tokenAccess.getUserIdToken();
    if (idToken != null && wsBaseUrl) updateWsUrl(idToken, "pilot");
    initAuthInfo(accessToken, "pilot");
  };

  useEffect(() => {
    checkIfAuthenticated();
  }, [location.pathname, navigate, dispatch]);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/v1");
    }
  }, [isLoggedIn, navigate]);

  return <Outlet />;
}

function App() {
  return (
    <BrowserRouter>
      <CssBaseline />
      <div className="app">
        <AppColourThemeProvider>
          <SnackbarMessage />
          <Routes>
            <Route element={<Auth />}>
              <Route path="/v1" element={<LandingPage />} />
              <Route path="/v1/dashboard" element={<DashboardPage />} />
              <Route path="/" element={<Auth />} />
              {/* // <Route path="/redirecting" element={<Redirect />}></Route> */}
              <Route path="/login" element={<LoginPage />} />
              <Route
                path="/v1/operationfocused/:focusedOperationId"
                element={<DirectAccess />}
              />
              <Route path="*" element={<LandingPage />} />
            </Route>
          </Routes>
        </AppColourThemeProvider>
      </div>
    </BrowserRouter>
  );
}

export default App;
