import { Auth } from "aws-amplify";

export function tokenHandler() {
  const getUserAccessToken = async () => {
    try {
      const session = await Auth.currentSession();
      return session.getAccessToken().getJwtToken() || null;
    } catch {
      console.log("No user signed in");
    }
    return null;
  };
  const getUserIdToken = async () => {
    try {
      const session = await Auth.currentSession();
      const idToken = session.getIdToken().getJwtToken();
      return idToken || null;
    } catch {
      console.log("No user signed in");
    }
    return null;
  };

  return {
    getUserAccessToken,
    getUserIdToken,
  };
}
