import axios from "axios";
import { tokenHandler } from "./tokenHandler";

const envVar = [
  "api_key-airmap",
  "api_key-mapbox",
  "api_key-openaip",
  "api_key-openweather",
  "app_client_id-cif",
  "app_client_secret-cif",
  "base_url-cif",
  "base_url-cif_atm",
  "base_url-dji_livestream",
  "base_url-mars",
  "base_url-notifications",
  "base_url-sp",
  "base_url-tracker_server",
  "cognito_domain-cif",
  "cognito_domain-non_authority",
  "dji-app-id",
  "dji-app-key",
  "dji-app-license",
  "gnss_api_url",
  "dji-app-secret",
  "base_url-mars-authority",
  "use_simulated_weather",
  "base_url-mavlink",
  "base_url-mavlink-ec2",
];

export async function parameterStore(requestArr = envVar) {
  const accessToken = await tokenHandler().getUserAccessToken();

  const parameterStoreUrl = axios.create({
    baseURL: process.env.REACT_APP_PARAMETER_STORE_URL,
  });

  const response = await parameterStoreUrl.post("", requestArr, {
    headers: {
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
  });
  return response;
}
